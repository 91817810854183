















































































































































































































.home {
  width: 100%;
  height: 100%;
  padding-bottom: 4em;
  h1 {
    line-height: 2em;
    .navigation {
      white-space: nowrap;
      float: right;
    }
  }
}
